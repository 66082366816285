import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Hero from '../components/general/Hero'
import Highlight from '../components/numbers/Highlight'
import TablaCausas from '../components/causas/TablaCausas'
//import CategoriasChart from '../components/charts/CategoriasChart'
// import EstadoChart from '../components/charts/EstadoCharts'
import LineaMemoriaVerdadJusticia from '../components/lineas/MemoriaVerdadJusticia'

const Home = () => {
    return (
        <>
            <Hero 
                firstLine="LOS JUICIOS"
                secondLine="EN CONTEXTO"
            />
            <Container>
                <Row>
                    <Col>
                        <Highlight
                            number="120"
                            name="Dato uno"
                            description="destacado"
                        />
                    </Col>
                     <Col>
                        <Highlight
                            number="120"
                            name="Dato uno"
                            description="destacado"
                        />
                    </Col>
                     <Col>
                        <Highlight
                            number="120"
                            name="Dato uno"
                            description="destacado"
                        />
                    </Col>
                     <Col>
                        <Highlight
                            number="120"
                            name="Dato uno"
                            description="destacado"
                        />
                    </Col>
                     <Col>
                        <Highlight
                            number="120"
                            name="Dato uno"
                            description="destacado"
                        />
                    </Col>
                     <Col>
                        <Highlight
                            number="120"
                            name="Dato uno"
                            description="destacado"
                        />
                    </Col>
                </Row>
            </Container>
            <TablaCausas /> 

            {/* <CategoriasChart 
             categoriasJuicios={causa.etiquetas}
             />            */}
            {/*<EstadoChart
             estadosJuicios={causa.caca_detalle}
            />*/}
            <LineaMemoriaVerdadJusticia/>          
        </>

    )
}

export default Home
