import React from 'react'
import {Navbar, Nav, Row, Col, Container} from 'react-bootstrap'
import { Link, useLocation } from "react-router-dom";

const Menu = () => {
    const currentPath = useLocation().pathname
    return (
        <Container fluid className="bb-lavanda">
            <Container>
                <Row>
                    <Col>
                        <Navbar expand="lg" className="justify-content-md-between">
                            <Navbar.Brand as={Link} to="/"><strong>LOGO</strong></Navbar.Brand>
                            <div>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav>
                                        <Nav.Link as={Link} className={currentPath === '/' ? 'active' : ''}to="/">INICIO</Nav.Link>
                                        <Nav.Link as={Link} className={currentPath.indexOf('/ficha-juicio') >=0 ? 'active' : ''}to="/ficha-juicio" >JUICIOS</Nav.Link>
                                        <Nav.Link as={Link} className={currentPath === '/chart-test' ? 'active' : ''}to="/chart-test" >MICRO RELATOS</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Menu
