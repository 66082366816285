import axios from './axios'
const CausaService = {
    itemsPorPagina : 3,
    getCausaById: (id) => axios.get("/generico?busqueda=_equal&campo=caus_id&valor=" + id),
    listCausasByNombre: (nombre) => axios.get("/causa/buscar/nombre/" + nombre),
    listCausasByJurisdiccion: (jurisdiccion) => axios.get("/causa/buscar/jurisdiccion/" + jurisdiccion),
    listCausasByEstado: (estado) => axios.get("/causa/buscar/estado/" + estado),
    listCausasByCategoria: (categoria) => axios.get("/causa/buscar/categoria/" + categoria),
    listNombres: () => axios.get("/causa/listado/solonombres"),
    listTodasLasCausas: () => axios.get("/causas/blob"),
    listByPagina: (numeroPagina, itemsPorPagina) => axios.get("/paginado?page=" + numeroPagina + "&size=" + itemsPorPagina)
}
export default CausaService