import React from 'react'
import ReactLoading from 'react-loading';


const Cargando = () => {
    return (
            <ReactLoading type={'balls'} color={'#ffffff'} height={'100%'} width={'100%'} />
    )
}

export default Cargando
