import { useEffect, useState } from "react";
import {
  Container,
  Table,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Spinner
} from "react-bootstrap";
import CausaService from "../../services/CausaService";
import Updated from "../general/Updated";
import RowCausa from './RowCausa'
import FiltroHeader from './FiltroHeader'
const TablaCausas = (data) => {
  const [causasList, setCausasList] = useState([]);
  const [cargandoMas, setCargandoMas] = useState(false);
  const [fechaActualizacion, setFechaActualizacion] = useState(null);
  let pagina = 1;
  useEffect(() => {
    cargarCausas();
  }, []);

  function cargarCausas() {
    setCargandoMas(true)
    CausaService.listByPagina(pagina, CausaService.itemsPorPagina)
      .then((response) => {
        if (response.data.resultado) {
          setCausasList([...causasList, ...response.data.resultado.causas]);
          setFechaActualizacion(new Date(response.data.resultado.fecha_actualizacion))
        } else {
           // window.location = '/error';
        }
        setCargandoMas(false);
      })
      .catch((err) => {
        // window.location = '/error';
      });
  }

  function cargarNMas() {
    pagina++;
    cargarCausas();

  }
  return <>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={8}>
            <InputGroup className="mb-3 search">
              <FormControl
                placeholder="Buscar"
                aria-label="Buscar"
                aria-describedby="basic-addon1"
                style={{ marginTop: "2em" }}
              />
            </InputGroup>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          <p style={{ margin: "1em", fontWeight: 600 }}>
            Hacé clic en cada juicio para ver más información
          </p>
        </Row>

        <Row className="justify-content-md-center">
          <Updated date={fechaActualizacion}/>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
          <Table responsive>
            <thead>
              <tr>
                <FiltroHeader nombre="Jurisdicción"/>
                <FiltroHeader nombre="Juicio"/>
                <FiltroHeader nombre="Día y hora de audiencia"/>
                <FiltroHeader nombre="Velocidad"/>
                <FiltroHeader nombre="Horas de audiencia"/>
                <FiltroHeader nombre="Imputados"/>
                <FiltroHeader nombre="Víctimas"/>
                <FiltroHeader nombre="Testigos"/>
                <FiltroHeader nombre="Categorías"/>
              </tr>
            </thead>
            <tbody>
              {causasList.map((causa) => (
                <RowCausa key={causa.caus_id} {...causa}/>
              ))}
            </tbody>
          </Table>
        </Row>
      </Container>
      <Container>
        <Row className="justify-content-md-center">
                {cargandoMas ? (
                  <><Spinner animation="border" className="mr-5" role="status">
                  </Spinner>
                  <span>Cargando ...</span></>
                ) : 
              (<Button onClick={cargarNMas}>Mostrar {CausaService.itemsPorPagina} juicios más</Button>
              )}
        </Row>
      </Container>
    </>
};
export default TablaCausas;
