import React from 'react'
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AnimatedSwitch } from 'react-router-transition';
import Menu from './Menu'
import {Home, ChartTest, FichaJuicio, Error} from '../../pages'
import routerTransitions from '../../routerTransitions'

const LesaRouter = () => {
    return (
        <Router>
            <Menu></Menu>
            <AnimatedSwitch
            atEnter={routerTransitions.bounceTransition.atEnter}
            atLeave={routerTransitions.bounceTransition.atLeave}
            atActive={routerTransitions.bounceTransition.atActive}
            mapStyles={routerTransitions.mapStyles}
            className="route-wrapper"
            >
            <Route exact path="/" component={Home} />
            <Route exact path="/chart-test" component={ChartTest} />
            <Route exact path="/ficha-juicio/:id_caus" component={FichaJuicio} />
            <Route exact path="/error" component={Error} />
            </AnimatedSwitch>
      </Router>
    )
}

export default LesaRouter
