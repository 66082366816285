import React from 'react'
import { Container } from 'react-bootstrap'

function Hero({bgClass = 'bg-gray', firstLine = '', secondLine = ''}) {
    return (
        <>
           <Container fluid className={[bgClass, 'pt-4', 'pb-4', 'bb-lavanda']}>
                <Container>
                    <h1 className="text-center">
                        <span style={{fontWeight:300}}>{firstLine}</span><br/>
                        <strong>{secondLine}</strong></h1>
                </Container>
            </Container> 
        </>
    )
}

export default Hero
