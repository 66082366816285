import {useEffect, useState} from 'react'
import Ficha from '../components/ficha/Ficha'
import CausaService from '../services/CausaService'
import Cargando from '../components/general/Cargando'
const FichaJuicio = (data) => {
    const [ficha, setFicha] = useState({});
    const [cargando, setCargando] = useState(true);
    const idCausa = data.match.params.id_caus
    useEffect(() => {
            CausaService.getCausaById(idCausa).then((response) => {
                if(response.data.success && response.data.resultado){
                    setFicha(response.data.resultado[0])                
                }else{
                    window.location = '/404';
                }
                setCargando(false)
                
            }).catch((err) => {
                return(<h1>ERROR</h1>)
            })   
    }, [idCausa])
    return cargando ? (
        <Cargando />
    ) :(
            <Ficha 
                nombre={ficha.caus_nombre_vulgar}
                caratula={ficha.caus_caratula}
                descripcion={ficha.caus_causa}
                cantidadVictimas={ficha.cant_victimas}
                victimas={ficha.victimas ? ficha.victimas.split(';') : []}
                cantidadImputados={ficha.cant_imputados}
                imputados={ficha.imputados ? ficha.imputados.split(';') : []}
                centro={ficha.caus_lugar}
            />
    )
}
export default FichaJuicio
