import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Chrono } from "react-chrono";
// https://github.com/prabhuignoto/react-chrono

const Timeline = ({titulo, datos}) => {
  const items = [
    {
      title: "May 1940",
      cardTitle: "Dunkirk",
      cardSubtitle:
        "Men of the British Expeditionary Force (BEF) wade out to..",
      cardDetailedText:
        "Men of the British Expeditionary Force (BEF) wade out to. saraza detailed.",
      media: {
        type: "IMAGE",
        source: {
          url: "http://someurl/image.jpg",
        },
      },
    },
    {
        title: "May 1943",
        cardTitle: "asdasd",
        cardSubtitle:
          "Men of the British Expeditionary Force (BEF) wade out to..",
        cardDetailedText:
          "Men of the British Expeditionary Force (BEF) wade out to. saraza detailed.",
        media: {
          type: "IMAGE",
          source: {
            url: "http://someurl/image.jpg",
          },
        },
      },
      {
          title: "May 1943",
          cardTitle: "asdasd",
          cardSubtitle:
            "Men of the British Expeditionary Force (BEF) wade out to..",
          cardDetailedText:
            "Men of the British Expeditionary Force (BEF) wade out to. saraza detailed.",
          media: {
            type: "IMAGE",
            source: {
              url: "http://someurl/image.jpg",
            },
          },
        },
        {
            title: "May 1943",
            cardTitle: "asdasd",
            cardSubtitle:
              "Men of the British Expeditionary Force (BEF) wade out to..",
            cardDetailedText:
              "Men of the British Expeditionary Force (BEF) wade out to. saraza detailed.",
            media: {
              type: "IMAGE",
              source: {
                url: "http://someurl/image.jpg",
              },
            },
          },
          {
              title: "May 1943",
              cardTitle: "asdasd",
              cardSubtitle:
                "Men of the British Expeditionary Force (BEF) wade out to..",
              cardDetailedText:
                "Men of the British Expeditionary Force (BEF) wade out to. saraza detailed.",
              
            },
            {
                title: "May 1943",
                cardTitle: "asdasd",
                cardSubtitle:
                  "Men of the British Expeditionary Force (BEF) wade out to..",
                cardDetailedText:
                  "Men of the British Expeditionary Force (BEF) wade out to. saraza detailed.",
               
              },
              {
                  title: "May 1943",
                  cardTitle: "asdasd",
                  cardSubtitle:
                    "Men of the British Expeditionary Force (BEF) wade out to..",
                  cardDetailedText:
                    "Men of the British Expeditionary Force (BEF) wade out to. saraza detailed.",
                 
                }
  ];

  return (
    <Container>
        <Row className="pt-5 mt-5">
            <Col><h2>{titulo}</h2></Col>
        </Row>
      <Row>
        <Col>
          <Chrono scrollable={{scrollbar: true}} /* mode="VERTICAL_ALTERNATING"*/ items={items} />
        </Col>
      </Row>
    </Container>
  );
};
export default Timeline;
