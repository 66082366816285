import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import BarChart from '../components/charts/BarChart'
import ShortLineChart from '../components/charts/ShortLineChart'
import LineChart from '../components/charts/LineChart'

const ChartTest = () => {
    return (
        <Container>
            <h2>Prueba gráficos</h2>
            <Row>
                <Col md={4} xs={12} style={{height:"15em"}}>
                    <BarChart
                        name="País"
                        data = {[
                        {
                          "País": "Argentina",
                          "Asintomáticos": 145,
                          "Confirmados": 131,
                          "Sospechosos": 0
                        },
                        {
                          "País": "Chile",
                          "Asintomáticos": 44,
                          "Confirmados": 10,
                          "Sospechosos": 136
                        },
                        {
                          "País": "Brasil",
                          "Asintomáticos": 175,
                          "Confirmados": 76,
                          "Sospechosos": 113
                        }]}
                        keys={[ 'Asintomáticos', 'Confirmados', 'Sospechosos']}
                    />
                </Col>
                <Col md={4} xs={12} style={{height:"15em"}}>
                    <BarChart
                        name="País"
                        data = {[
                        {
                          "País": "Argentina",
                          "Asintomáticos": 145,
                          "Confirmados": 131,
                          "Sospechosos": 0
                        },
                        {
                          "País": "Chile",
                          "Asintomáticos": 44,
                          "Confirmados": 10,
                          "Sospechosos": 136
                        },
                        {
                          "País": "Brasil",
                          "Asintomáticos": 175,
                          "Confirmados": 76,
                          "Sospechosos": 113
                        }]}
                        keys={[ 'Asintomáticos', 'Confirmados', 'Sospechosos']}
                    />
                </Col>
                <Col md={4} xs={12} style={{height:"15em"}}>
                    <BarChart
                        name="País"
                        data = {[
                        {
                          "País": "Argentina",
                          "Asintomáticos": 145,
                          "Confirmados": 131,
                          "Sospechosos": 0
                        },
                        {
                          "País": "Chile",
                          "Asintomáticos": 44,
                          "Confirmados": 10,
                          "Sospechosos": 136
                        },
                        {
                          "País": "Brasil",
                          "Asintomáticos": 175,
                          "Confirmados": 76,
                          "Sospechosos": 113
                        }]}
                        keys={[ 'Asintomáticos', 'Confirmados', 'Sospechosos']}
                    />
                </Col>
            </Row>       
            <Row>
              <Col md={3} xs={4} style={{height:"10em"}}>
                  <ShortLineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 33
                          },
                          {
                              "x": "3er mes",
                              "y": 88
                          }
                          ]
                      }]
                  }
                  />
              </Col>
              <Col md={3} xs={4} style={{height:"10em"}}>
                  <ShortLineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 20
                          },
                          {
                              "x": "3er mes",
                              "y": 100
                          }
                          ]
                      }]
                  }
                  />
              </Col>
              <Col md={3} xs={4} style={{height:"10em"}}>
                  <ShortLineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 75
                          },
                          {
                              "x": "3er mes",
                              "y": 100
                          }
                          ]
                      }]
                  }
                  />
              </Col>
              <Col md={3} xs={4} style={{height:"10em"}}>
                  <ShortLineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 33
                          },
                          {
                              "x": "3er mes",
                              "y": 50
                          }
                          ]
                      }]
                  }
                  />
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12} style={{height:"10em"}}>
                  <LineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 33
                          },
                          {
                              "x": "3er mes",
                              "y": 88
                          }
                          ]
                      }]
                  }
                  />
              </Col>
              <Col md={6} xs={12} style={{height:"10em"}}>
                  <LineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 20
                          },
                          {
                              "x": "3er mes",
                              "y": 100
                          }
                          ]
                      }]
                  }
                  />
              </Col>
              <Col md={6} xs={12} style={{height:"10em"}}>
                  <LineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 75
                          },
                          {
                              "x": "3er mes",
                              "y": 100
                          }
                          ]
                      }]
                  }
                  />
              </Col>
              <Col md={6} xs={12} style={{height:"10em"}}>
                  <LineChart
                    data={[
                      {
                        "data": [
                          {
                              "x": "1er mes",
                              "y": 15
                          },
                          {
                              "x": "2do mes",
                              "y": 33
                          },
                          {
                              "x": "3er mes",
                              "y": 50
                          }
                          ]
                      }]
                  }
                  />
              </Col>
            </Row>
        </Container>
    )
}

export default ChartTest
