import React from 'react'

const Updated = ({date}) => {
    return date ? (
        <div className="text-center">
            <p className="">Datos actualizados al día: {date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()}</p>
         </div>   
    ): null
}

export default Updated