import { ResponsiveBubble } from '@nivo/circle-packing'
const BubbleChart = (data) => {
    return <ResponsiveBubble
    root={data.root}
    identity={data.identity}
    value={data.value}
    colors={{ scheme: 'paired' }}
    labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 0.8 ] ] }}
    borderWidth={2}
    borderColor={{ from: 'color' }}
    enableLabel={false}
    animate={true}
    colorBy="name"
    motionStiffness={90}
    motionDamping={12}
/>
}

export default BubbleChart