import { Col, Container, Row, Accordion, Card, Button } from "react-bootstrap"
import HighlightCard from '../general/HighlightCard'
const Ficha = ({nombre, descripcion, cantidadVictimas= "0", victimas = [], cantidadImputados= "0", imputados, centro = []}) => {
    return (
        <Container fluid className="bg-dark text-white pt-5 pb-5">
            <Container>
                <Row>
                    <Col md={{ span: 10, offset: 1 }} >
                        <h2 className="text-lima">{nombre}</h2>
                        <p>{descripcion}</p>
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col md={{ span: 10, offset: 1 }}>
                        <HighlightCard
                            icons={['icono-arg-hombre', 'icono-arg-mujer']}
                        >
                            <span style={{fontSize:"2em", fontWeight:600}} className="text-lima">{cantidadVictimas} Víctima{cantidadVictimas === 1 ? '' : 's'}</span>

                            <Accordion>
                                <Card style={{backgroundColor:'transparent', width:'fit-content'}}>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <p style={{marginBottom:'0em'}} >Ver nombres y apellidos de las <strong>víctimas</strong></p>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body style={{backgroundColor:'white'}}> {victimas.map((_) => <p key={"vic-" + Math.random()} style={{color:'black',marginBottom:'0em'}}>{_}</p>)}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                        
                                </Accordion>

                        </HighlightCard>                        
                    </Col>
                </Row>
                <Row className="pt-4">
                    <Col md={{ span: 10, offset: 1 }}>
                        <HighlightCard
                            icons={['icono-arg-martillo-negativo']} 
                        >
                        
                            <span style={{fontSize:"2em", fontWeight:600}} className="text-lima">{cantidadImputados} Imputado{cantidadImputados === 1 ? '' : 's'}</span>


                            <Accordion>
                                <Card style={{backgroundColor:'transparent', width:'fit-content'}}>
                                    <Accordion.Toggle as={Card.Header} eventKey="0">
                                    <p style={{marginBottom:'0em'}} >Ver nombres y apellidos de los <strong>imputados</strong></p>
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body style={{backgroundColor:'white'}}> {imputados.map((_) => <p key={"imp-" + Math.random()} style={{color:'black',marginBottom:'0em'}}>{_}</p>)}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                        
                                </Accordion>

                     
                        </HighlightCard>                        
                    </Col>
                </Row>

                <Row>
                    <Col md={{ span: 10, offset: 1 }}>
                        <h5 style={{fontWeight:500}}>CENTRO CLANDESTINO DE DETENCIÓN</h5>
                        <p className="text-lima centro" style={{fontSize:'2em', fontWeight:600}}>{centro}</p>

                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Ficha
