import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Error = () => {
    return (
        <Container fluid className="bg-danger text-white pt-5 pb-5">
            <Container className="mt-5 mb-5" >
                <Row>
                    <Col xs={12} sm={2}>
                        <i className="icono-arg-denuncia_1 fa-5x"></i>
                    </Col>
                    <Col xs={12} sm={10}>
                        <h2>Ups!</h2>
                        <h3>Ocurrió un error al cargar la información. </h3>
                        <p>Intentá nuevamente más tarde.</p>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Error
