import React from 'react'

function Highlight({number, name, description}) {
    return (
        <div className="text-center">
            <p className="text-lima align-text-bottom" style={{fontSize:"3.5em", marginBottom:"0", fontWeight:600}}>{number}</p>
            <p style={{marginTop:"0"}}><strong>{name}</strong><br/>{description}
            </p>
        </div>
    )
}

export default Highlight
