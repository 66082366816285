import { ResponsiveLine } from '@nivo/line'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
const LineChart = ({ data, lineWidth, ...otherProps  }) => (
    <ResponsiveLine
        data = {data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: true, reverse: false }}
        colors={{ scheme: 'purple_orange' }}
        axisTop={null}
        axisRight={null}
        curve="natural"
        lineWidth={lineWidth ? lineWidth : "1"}
        pointSize={4}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={3}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        useMesh={true}
        {...otherProps}
    />
)

export default LineChart