const ImputadoSevice = {
    getDescripcionEstado: (estado) => {
        switch (estado) {
            case 'CD':case 'CONDENADO': return 'CONDENADO';
            case 'IN':case 'INCAPACIDAD': return 'INCAPACIDAD';
            case 'ME':case 'FALTA DE MERITO': return 'FALTA DE MERITO';
            case 'PC':case 'PENA CUMPLIDA': return 'PENA CUMPLIDA';
            case 'PR':case 'PROCESADO': return 'PROCESADO';
            case 'RB':case 'REBELDIA': return 'REBELDIA';
            case 'SB':case 'SOBRESEIDO/ABSUELTO': return 'SOBRESEIDO/ABSUELTO';
            case 'SF':case 'SOBRESEIDO POR FALLECIMIENTO': return 'SOBRESEIDO POR FALLECIMIENTO';
            case 'SM':case 'CON INDAGATORIA': return 'CON INDAGATORIA';
            default:
                return "N/A";
        }    
    }
}
export default ImputadoSevice