import { Col } from "react-bootstrap";
import BubbleChart from "./BubbleChart";
import ImputadoService from '../../services/ImputadoService'
const AccussedChart = ({
  totalAccussed,
  byStatus
}) => {
    let data = byStatus ? byStatus.split(';').map((_) => {
        const status = _ ? _.split(':')[0].trim() : null;
        const quantity = _ ? _.split(':')[1].trim() : null;
        return {name: ImputadoService.getDescripcionEstado(status), loc:parseInt(quantity)}
    }) : null;
  return (
    <Col xs={12} style={{ height: "4em", minWidth: 0 }}>
      <BubbleChart
        identity="name"
        value="loc"
        root={{"name": "Imputados", "children": data}}
      />
    </Col>
  );
};
export default AccussedChart;
