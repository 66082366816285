import axios from 'axios'
//axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const instanceWithBaseURL = axios.create({
    //baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

export default instanceWithBaseURL
