import LineChart from './LineChart'
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
const ShortLineChart = ({ data }) => (
    <LineChart
        data = {data}
        axisLeft={true}
        axisBottom={true}
        lineWidth="1"
        enablePoints={true}
        pointSize="4"
        pointBorderWidth="2"
        enableGridX={true}
        enableGridY={true}
        margin={{}}
    />
)

export default ShortLineChart