import { Col } from "react-bootstrap";
import BarChart from "./BarChart";
const AudienceDaysChart = ({
  diasInicio,
  diasRequerimiento,
  diasElevacion,
  diasAudiencia,
  diasDebate,
  promedioHorasmensuales,
  duracionUltiaudiencia
}) => {
  return (
    <Col xs={12} style={{ height: "250px", minWidth: "150px" }}>
      <BarChart
        name="Etapa"
        data={[
          //{"Promedio horas mensuales" : promedioHorasmensuales},
          {"Duración última audiencia" : duracionUltiaudiencia},

            {"Etapa": "Debate", "Días": diasDebate},
            {"Etapa": "Audiencia", "Días": diasAudiencia},
            {"Etapa": "Elevación", "Días": diasElevacion},
            {"Etapa": "Requerimiento", "Días": diasRequerimiento},
            {"Etapa": "Inicio", "Días": diasInicio}
        ]}
        keys={["Días"]}
      />
    </Col>
  );
};
export default AudienceDaysChart;
