import {Col} from 'react-bootstrap'
import ShortLineChart from './ShortLineChart'
import LineChart from './LineChart'

// TODO: Invertir ejes!!
const VelocityChart = ({ fechaRequerimiento,
    fechaElevacion,
    fechaAudienciaPreliminar,
    fechaDebate,
    fechaSentencia }) => {
    let data = [];
    if(fechaRequerimiento){
        data.push({"y": new Date(fechaRequerimiento).getTime(), "x": "Requerimiento"});
    }
    if(fechaAudienciaPreliminar){
        data.push({"y": new Date(fechaAudienciaPreliminar).getTime(), "x": "Audiencia Preliminar"});
    }
    if(fechaDebate){
        data.push({"y": new Date(fechaDebate).getTime(), "x": "Debate"});
    }
    if(fechaSentencia){
        data.push({"y": new Date(fechaSentencia).getTime(), "x": "Sentencia"});
    }
    return (
        <Col xs={10} style={{height:"250px", minWidth: "350px"}}>
        <LineChart
            data={[
            {
                "data": data
            }]
        }
        />
      </Col>)
}
export default VelocityChart