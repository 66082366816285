import AudienceDaysChart from '../charts/AudienceDaysChart'
import VelocityChart from '../charts/VelocityChart'
//import MyResponsiveLine from '../charts/VelocidadChart2'
import AccusedChart from '../charts/AccusedChart'
import {Link} from 'react-router-dom'
import {Badge} from 'react-bootstrap'
const RowCausa = (causa) => {
    return (
        <tr>
            <td>{causa.jurisdiccion}</td>
            <td>
                <Link to={{pathname: "/ficha-juicio/" + causa.caus_id}}>
                    {causa.caus_nombre_vulgar}
                </Link>
            </td>
            <td>{causa.audiencias}</td>
            <td>
                <VelocityChart 
                    fechaRequerimiento={causa.requerimiento}
                    fechaElevacion={causa.elevacion}
                    fechaAudienciaPreliminar={causa.audiencia_preliminar}
                    fechaDebate={causa.debate}
                    fechaSentencia={causa.sentencia}
                />
            </td>
            <td>
                <AudienceDaysChart
                promedioHorasmensuales={causa.audiencia_duracion_promedio}    
                duracionUltiaudiencia={causa.duracion_ultima_audiencia}
                diasInicio={causa.dias_inicio}
                diasRequerimiento={causa.dias_requerimiento}
                diasElevacion={causa.dias_elevacion}
                diasAudiencia={causa.dias_audiencia}
                 diasDebate={causa.dias_debate}
             />
             </td>
            <td>
                <AccusedChart
                    totalAccussed={causa.cant_imputados}
                    byStatus={causa.cant_por_estado_procesal}
                />
            </td>
            <td>{causa.cant_victimas}</td>
            <td>{causa.cant_testigos}</td>
            <td>{causa.etiquetas ? causa.etiquetas.split(';').map((tag) => 
                <Badge key={tag + '_' + Math.random()} pill variant="secondary">{tag}</Badge>
            ) : null}</td>
        </tr>
        
    )
}
export default RowCausa;