import React from 'react'
import { Col, Row, Card } from "react-bootstrap"
const HighlightCard = ({icons, collapseName, ...props}) => {
    return (
        <Card className="bg-dark">
            <Card.Body>
                <Row>
                    <Col xs={2} className="text-center">
                        {icons.map((_) =>
                            <i className={[_, 'mr-3', 'fa-3x'].join(' ')} key={Math.random()*15000}></i>
                        )}
                    </Col>
                    <Col>
                        {props.children}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default HighlightCard
